import React, { useEffect, useState } from 'react';
import './coinpool.css';
import axios from 'axios';
import { useAuthContext } from '../../hooks/useAuthContext';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading/Loading';

const CoinPool = () => {
    const {user}=useAuthContext()
    const [cryptoData, setCryptoData] = useState([]);
    const [view, setView] = useState(null);
    const [data, setData] = useState(null);
    const [plan, setPlan] = useState(0);
    const [expandedTokenAddress, setExpandedTokenAddress] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(user)
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/`, {
                    headers: { Authorization: `Bearer ${user.token}` }
                });

                if (response.data.plan.planId === null) {
                    // dispatch({ type: 'ERROR', payload: 'You Have No Active Plan' });
                    navigate('/dashboard');
                } else {
                    setData(response.data);
                }
            } catch (error) {
                if (error.response?.data?.error === 'email not verified') {
                    navigate('/verify');
                }
            }
        };

        fetchUserData();
    }, [user, navigate]);

    useEffect(() => {
        const fetchPlanData = async () => {
            try {
                if (data) {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/plans/${data.plan.planId}`);
                    setPlan(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchPlanData();
    }, [data]);

    const toggleTokenAddress = (id) => {
        setExpandedTokenAddress(prev => (prev === id ? null : id));
    };

    useEffect(() => {
        const fetchCryptoData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-tokens`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Content-Type": "application/json"
                    }
                });
                setCryptoData(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchCryptoData();
    }, [user.token]);

    const formatNumberWithCommas = (number) => number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    useEffect(() => {
        const toggleFlickerEffect = () => {
            const pnlCells = document.querySelectorAll(".pnl-cell, .netpnl-cell");
            pnlCells.forEach(cell => cell.classList.add("flicker"));
            setTimeout(() => {
                pnlCells.forEach(cell => cell.classList.remove("flicker"));
            }, 500);
        };

        const intervalId = setInterval(toggleFlickerEffect, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
       <>
       {
        cryptoData.length>0? <div className="container-fluid">
        <div className="table-container">
            <table className="crypto-table">
                <thead>
                    <tr>
                        <th scope="col">Active</th>
                        <th scope="col">Token</th>
                        <th scope="col">Chain Id</th>
                        <th scope="col">Action</th>
                        <th scope="col">Order Status</th>
                        <th scope="col">Amount</th>
                        <th scope="col">PNL</th>
                        <th scope="col">Net PNL</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {cryptoData.map((crypto) => (
                        <tr key={crypto._id} onClick={() => setView(crypto)}>
                            <td>
                                <p style={{ color: "red" }}>Live Trading</p>
                                <p style={{ color: "blue" }}>{new Date(crypto.createdAt).toLocaleDateString()}</p>
                            </td>
                            <td>
                                <span className="text-warning" style={{ cursor: 'pointer' }}>View</span>
                            </td>
                            <td style={{ color: "white" }}>{crypto.chainId}</td>
                            <td style={{ color: "white" }}>
                                <p>Buy</p>
                                <p>Sell</p>
                            </td>
                            <td>
                                <p>Filled</p>
                                <p>Filled</p>
                            </td>
                            <td>
                                <p>0.1</p>
                                <p>0.1</p>
                            </td>
                            <td className="pnl-cell">
                                <p>{formatNumberWithCommas(plan.dailyTurnover)}</p>
                                <p>{formatNumberWithCommas(plan.dailyTurnover)}</p>
                            </td>
                            <td className="netpnl-cell">
                                <p>{formatNumberWithCommas(plan.dailyTurnover)}</p>
                                <p>{formatNumberWithCommas(plan.dailyTurnover)}</p>
                            </td>
                            <td>
                                <p style={{ color: "green" }}>Completed</p>
                                <p style={{ color: "blue" }}>{new Date(crypto.createdAt).toLocaleDateString()}</p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        {view && (
            <div className="view-desc">
                <FaTimes onClick={() => setView(null)} style={{ cursor: 'pointer' }} />
                <div className="desc-details">
                    <img src={view.icon} alt="Token" />
                    <div className="description">
                        <p>{view.description}</p>
                        <p>
                            Token Address: 
                            <span 
                                className={`token-address ${expandedTokenAddress === view._id ? 'expanded' : ''}`}
                                onClick={() => toggleTokenAddress(view._id)}
                            >
                                {view.tokenAddress}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        )}
    </div>:<div><Loading/></div>
       }
       </>
    );
};

export default CoinPool;
