import React from 'react'
import facebookIcon from './img/fabook-icon-white.svg';
import twitterIcon from './img/twitter-icon-white.svg';
import linkedinIcon from './img/inkedin-icon-white.svg';
import whatsappIcon from './img/whatsapp-icon-white.svg';
import logoWhite from '../../resources/images/logo.png';
import { Link } from 'react-router-dom';

function CryptoFooter() {
  return (
    <div className='crypto'>
      <footer>
    <div class="crypto-joinSection">
        <div class="crypto-joinDesc">
            <h1 class="crypto-sectionHeader">Join with us</h1>
            <p class="crypto-sectionPara">
            Join us for a high-growth approach to crypto investing. Start now and experience the power of strategic, bot-assisted trading focused on consistent 50% biweekly returns
            </p>
        </div>
       <Link to='/login'> <button class="crypto-btn crypto-primaryBtn">JOIN NOW</button></Link>
    </div>

    <div class="crypto-footerlinksContainer">
    <div className="crypto-footerAboutus">
            <img src={logoWhite} alt="Prouple Logo" />
            <p className="crypto-darkPara">
                With no commissions and a simple user interface, 
                Swift HFT is the most reliable way to trade for 
                Cryptocurrency.
            </p>
            {/* <div className="crypto-footersociallinkContainer">
                <img className="crypto-sociallink" src={facebookIcon} alt="Facebook" />
                <img className="crypto-sociallink" src={twitterIcon} alt="Twitter" />
                <img className="crypto-sociallink" src={linkedinIcon} alt="LinkedIn" />
                <img className="crypto-sociallink" src={whatsappIcon} alt="WhatsApp" />
            </div> */}
        </div>

        <div className="crypto-footerlink">
    <h1 className="crypto-linkTitle">Explore</h1>
    <Link to="/about-us" className="crypto-eachLink">About Us</Link>
    <Link to="/contact-us" className="crypto-eachLink">Contact Us</Link>
    <Link to="/plans" className="crypto-eachLink">Pricing</Link>
</div>


<div className="crypto-footerlink">
    <h1 className="crypto-linkTitle">Service</h1>
    <Link to="/deposit" className="crypto-eachLink">Deposit</Link>
    <Link to="/withdraw1" className="crypto-eachLink">Withdraw</Link>
</div>


        <div class="crypto-footerlink">
            <h1 class="crypto-linkTitle">Resource</h1>
            <Link to="/dashboard" className="crypto-eachLink">Dashboard</Link>
    <Link to="/coin-pool" className="crypto-eachLink">Coin Pool</Link>
        </div>
    </div>

    <div class="crypto-footerCopyright">
    <p>&copy; 2023 <a href="#" class="crypto-developedBy">SWIFT HFT</a>. All rights reserved.</p>

    </div>
</footer>
    </div>
  )
}

export default CryptoFooter
