import React from 'react';
import contactImage from './img/contact-img.png';
import './commonStyle.css';
import './style.css';
import Header from './Header';
import CryptoFooter from './CryptoFooter';
import Navbar from '../../components/navbar/Navbar';

function CryptoContact() {
  return (
    <div className='crypto'>
        <Navbar />
      <section className="crypto-gridSection crypto-contactSection">
        <div className="crypto-contactformContainer">
          <h1 className="crypto-sectionHeader">Get in touch</h1>
          <form action="#" className="crypto-contactForm">
            <div>
              <input type="text" placeholder="Full name" className="crypto-contactInput" />
              <input type="text" placeholder="Email" className="crypto-contactInput" />
            </div>
            <textarea cols="30" rows="5" placeholder="Message" className="crypto-contactInput"></textarea>
            <button type="submit" className="crypto-btn crypto-primaryBtn crypto-contactBtn">Submit</button>
          </form>
        </div>
        <div className="crypto-sectionPic crypto-bouncepic crypto-contactPic" id="sectionPic">
          <img src={contactImage} alt="Contact" />
        </div>
      </section>

      {/* <h1 className="crypto-addressHeader">Find us Quickly</h1>
      <div className="crypto-address">
        <div className="crypto-eachAddress">
          <h1>Tokyo</h1>
          <p>Phone: (814) 842-3838</p>
          <p>Address: 264 Pine Pitch Rd, Buffalo Mills, Pennsylvania(PA), 15534</p>
          <p>Email: demo@website.com</p>
        </div>
        <div className="crypto-eachAddress">
          <h1>USA</h1>
          <p>Phone: (814) 842-3838</p>
          <p>Address: 264 Pine Pitch Rd, Buffalo Mills, Pennsylvania(PA), 15534</p>
          <p>Email: demo@website.com</p>
        </div>
        <div className="crypto-eachAddress">
          <h1>Canada</h1>
          <p>Phone: (814) 842-3838</p>
          <p>Address: 264 Pine Pitch Rd, Buffalo Mills, Pennsylvania(PA), 15534</p>
          <p>Email: demo@website.com</p>
        </div>
      </div> */}
      <CryptoFooter />
    </div>
  );
}

export default CryptoContact;
