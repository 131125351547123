import React, { useState } from 'react'
import './footer.css'
import {Link} from 'react-router-dom'
import ChoosePay from '../choose pay/ChoosePay'
import FooterLogo from '../../resources/images/footerlogo.png'


function Footer() {
  const [showDepo,setShowDepo]=useState(false)
  const [showWith,setShowWith]=useState(false)
  return (
  <>
  
  </>
  )
}

export default Footer
