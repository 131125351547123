import React, { useRef } from 'react'
import facebook from './img/fabook-icon-white.svg'
import twitter from './img/twitter-icon-white.svg'
import inkedin from './img/inkedin-icon-white.svg'
import  whatsapp from './img/whatsapp-icon-white.svg'
import { useLogout } from '../../hooks/useLogout'
import {Link} from 'react-router-dom'
import { useState } from 'react'
import {FaBars,FaTimes} from 'react-icons/fa'

import { useAuthContext } from '../../hooks/useAuthContext'
// import LogoutMenu from '../menus/LogoutMenu'
// import LoggedInMenu from '../menus/LoggedInMenu'
import logo from '../../resources/images/logo.png'
import LoggedInMenu from '../../components/menus/LoggedInMenu'
import LogoutMenu from '../../components/menus/LogoutMenu'
function Header() {
    const [toggleMenu,setToggleMenu]=useState(false)
    const {logout}=useLogout()
    const {user}=useAuthContext()
     const [isMenuOpen, setIsMenuOpen] = useState(false);
     const menuToggleRef = useRef(null);
    const handleLogout=()=>{
        logout()

    }
  return (
    <div className='crypto'>
      <header className='crypto-data'>
    <div id="logo">
        <a href="./index.html" >
        <img src="./img/logo-white.png" alt="" />
        </a>
    </div>
    <ul class="crypto-nav" id="nav">
        <ul class="crypto-navLogo">
            <a href="./index.html">
                 <img src="./img/logo-white.png" alt=""/>
           </a>
        </ul>
        <div className="nav-links">
            {user ? <><LoggedInMenu />
            <div className='logout'><button onClick={handleLogout}>Logout</button></div>
            </> : <LogoutMenu/>}
         
        </div>
        {!user ?(
             <div className="auth">
                 <div className="authlogo">
                 <img src={logo} alt="logo" />
                 </div>
             <Link to='/login'><p>Log in</p></Link>
                 <Link to='/plans'><p className='signup'>Auto Sale Bots</p></Link>
                 
     
                 
             </div>
         ):(
            <div className="auth">
                 <div className="authlogo">
                 <img src={logo} alt="logo" />
                 </div>
                 
     
                 
             </div>
         )}
        <div className="mobile-menu">
            {toggleMenu ?
            <FaTimes size={27} color="#000" style={{position:'absolute' ,zIndex:'999'} }onClick={()=>setToggleMenu(false)}  className='hbmenu'/>
            :<FaBars color="#fff" size={27} style={{position:'absolute', zIndex:'999'}} onClick={()=>setToggleMenu(true)} className='hbmenu'/>
            }
            {toggleMenu ?(
                <div className='mobile-menu-container slit-in-diagonal-1'>
                    <div className="mobile-menu-links">
                    {user ? <><LoggedInMenu />
            <div className='mob-logout'><button onClick={handleLogout}>Logout</button></div>
            </> : <LogoutMenu/>}
                    </div>
                    {!user &&(
                         <div className="mob-menu-auth">
                         <Link to='/login'><p>Log in</p></Link>
                         <Link to='/plans'><p className='signup'>Auto Sale Bots</p></Link>
                         </div>

                    )}
                   
                    
                </div>

            ):(
                <div className='mobile-menu-container slit-out-vertical'>
                    <div className="mobile-menu-links">
                    {user ? <><LoggedInMenu />
            <div className='mob-logout'><button onClick={handleLogout}>Logout</button></div>
            </> : <LogoutMenu/>}
                    </div>
                    {!user &&(
                         <div className="mob-menu-auth">
                         <Link to='/login'><p>Log in</p></Link>
                         <Link to='/plans'><p className='signup'>Auto Sale Bots</p></Link>
                         </div>

                    )}
                   
                    
                </div>

            )}
            

  



            
        </div>
    </ul>
    {/* <div id="barContainer">
        <i id="bar" class="fa-solid fa-bars"><FaBars /></i>
    </div> */}
</header>
    </div>
  )
}

export default Header
