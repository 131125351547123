import { useAuthContext } from "./useAuthContext";



 const useLogout=()=>{
  const {dispatch}=useAuthContext()

    const logout=()=>{

      localStorage.removeItem('user')
      dispatch({type:'LOGOUT'})
    }
    return {logout}
}
export {useLogout}