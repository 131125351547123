// CryptoHome.js
import React, { useState } from 'react';
import './commonStyle.css';
import './style.css';
import { FaBars, FaTimes, FaPlay } from 'react-icons/fa';
import bitcoin from './img/bitcoin-icon.png'
import ethereum from './img/ethereum-icon.png'
import tether from './img/tether-icon.png'
import hero from './img/hero-image.png'

import handshake from './img/handshake-icon-white-line.svg'
import handshake2 from './img/cart-icon-white-line.svg'
import handshake3 from './img/chain-process-img.png'
import market1 from './img/buy-icon-color.svg' 
import market2 from './img/trading-icon-color.svg'
import market3 from './img/support-icon-color.svg'
import percentIcon from './img/persent-icon-white.svg';
import bitcoinIcon from './img/bitcoin-icon-white.svg';
import ethereumIcon from './img/ethereum-white-icon.svg';
import handshakeIcon from './img/handshake-icon-white.svg';
import dashboardDark from './img/dashboard-dark.jpg';
import market4 from './img/online-icon-color.svg'
import currencyIcon from './img/cryptocurrency-white-icon.svg';
import blockchainIcon from './img/blockchain-white-icon.svg';
import sellIcon from './img/cryptocurrency-sell-white-icon.svg';
import cardIcon from './img/cryptocurrency-card-white-icon.svg';
import newsletterImage from './img/newsletter.png';
import Header from './Header';
import CryptoFooter from './CryptoFooter';
import Navbar from '../../components/navbar/Navbar';
import { Link } from 'react-router-dom';

const CryptoHome = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleToggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

  return (
<div className='crypto'>
    <Navbar />

<section class="crypto-hero crypto-gridSection">
    <div class="crypto-sectionDesc">
        <h1 class="crypto-headline">
        Achieve Up to 50% Returns Every Two Weeks <span class="crypto-cryptoText">Crypto Trading</span>.
        </h1>
        <p class="crypto-sub-headline">
        Welcome to Swift HFT, where innovation meets crypto investing. Our platform is designed to help you achieve 50% returns every two weeks by capitalizing on high-potential, newly launched tokens. With sniper bot technology for precise, early entry on promising tokens, we deliver an edge in this fast-moving market. Plus, we only generate income from the additional profits we earn, ensuring our success is directly tied to yours.
        </p>
        <div class="crypto-btnContainer">
            <button class="crypto-btn crypto-btn1">Contact Now</button>
            <button class="crypto-btn crypto-btn2">Trade Now <FaPlay /></button>
        </div>
    </div>
    <div class="crypto-sectionPic crypto-bouncepic" id="sectionPic">
        <img src={hero} alt="" />
    </div>
</section>


<section>
    <div class="crypto-carouselContainer">
        <div class="crypto-eachCarousel crypto-eachCarouselBorder">
            <img src={bitcoin} alt="" />
            <article class="crypto-carouselDesc">
                <h1 class="crypto-carouselTitle">Bitcoin</h1>
                <p class="crypto-carouselPara">Bitcoin is an innovative payment network</p>
                <div class="crypto-carouselPrice">
                    {/* <h3>$34,000</h3> */}
                    <span class="crypto-rect"></span>
                    {/* <h3 class="crypto-carouselDiscount">15%</h3> */}
                </div>
               <Link to={'/plans'}> <button class="crypto-btn crypto-carouselBtn">Buy & Trade</button></Link>
            </article>
        </div>

        <div class="crypto-eachCarousel">
            <img src={ethereum} alt=""/>
            <article class="crypto-carouselDesc">
                <h1 class="crypto-carouselTitle">Ethereum</h1>
                <p class="crypto-carouselPara">Ethereum is open-source blockchain currency</p>
                <div class="crypto-carouselPrice">
                    {/* <h3>$25,600</h3> */}
                    <span class="crypto-rect"></span>
                    {/* <h3 class="crypto-carouselDiscount">9%</h3> */}
                </div>
                <Link to={'/plans'}> <button class="crypto-btn crypto-carouselBtn">Buy & Trade</button></Link>
            </article>
        </div>

        <div class="crypto-eachCarousel">
            <img src={tether} alt=""/>
            <article class="crypto-carouselDesc">
                <h1 class="crypto-carouselTitle">Tether</h1>
                <p class="crypto-carouselPara">Tether is a stable coin cryptocurrency</p>
                <div class="crypto-carouselPrice">
                    {/* <h3>$7,000</h3> */}
                    <span class="crypto-rect"></span>
                    {/* <h3 class="crypto-carouselDiscount">4%</h3> */}
                </div>
                <Link to={'/plans'}> <button class="crypto-btn crypto-carouselBtn">Buy & Trade</button></Link>
            </article>
        </div>

    </div>
    <div class="crypto-carouselIndicator">
        <div class="crypto-indicator crypto-activeIndicator" onclick="slideCarousel(0)"></div>
        <div class="crypto-indicator" onclick="slideCarousel(1)"></div>
        <div class="crypto-indicator" onclick="slideCarousel(2)"></div>
        <div class="crypto-indicator" onclick="slideCarousel(3)"></div>
        <div class="crypto-indicator" onclick="slideCarousel(4)"></div>
        <div class="crypto-indicator" onclick="slideCarousel(5)"></div>
    </div>
</section>


<section class="crypto-gridSection">
    <div class="crypto-sectionDesc crypto-processessDesc">
        <h1 class="crypto-sectionHeader">Chain Process</h1>
        <p class="crypto-sectionPara">We do not charge any fees and we only require 
          you to register, buy a plan and start trading. You keep your privacy and your 
          coins.
        </p>
        <div class="crypto-eachProcesses">
            <img src={handshake} alt="handshake"/>
            <div class="crypto-eachprocessesPara">
                <h1 class="crypto-processTitle">Trading</h1>
                <p>
                    The act of speculating on cryptocurrency price movements 
                    via a Swift trading account, or buying and selling.
                </p>
            </div>
        </div>
        <div class="crypto-eachProcesses">
            <img src={handshake2} alt="handshake"/>
            <div class="crypto-eachprocessesPara">
                <h1 class="crypto-processTitle">Buying</h1>
                <p>
                Trade top cryptocurrency pairs, including Ethereum, Solana, Bitcoin, and more, all available on our platform.
                </p>
            </div>
        </div>
    </div>
    <div class="crypto-sectionPic crypto-bouncepic crypto-processesPic" id="sectionPic">
        <img src={handshake3} alt=""/>
    </div>
</section>


<section class="crypto-gridSection">
    <div class="crypto-sectionDesc crypto-marketDesc">
        <h1 class="crypto-sectionHeader">Markets at finger</h1>
        <p class="crypto-sectionPara">The Blockchain is a decentralized, 
            digital ledger of transactions that are recorded 
            confirmed
        </p>
        <div class="crypto-eachMarket">
            <img src={market1} alt="handshake"/>
            <div>
                <h1 class="crypto-marketTitle">50% Biweekly Target</h1>
                <p class="crypto-darkPara">
                We aim to deliver substantial gains every two weeks, focusing on rapid, high-potential opportunities.
                </p>
            </div>
        </div>
        <div class="crypto-eachMarket">
            <img src={market2} alt="handshake"/>
            <div>
                <h1 class="crypto-marketTitle">Sniper Bot Precision</h1>
                <p class="crypto-darkPara">
                Our automated sniper bot positions us at the forefront of each token launch, securing early access
                </p>
            </div>
        </div>
        <div class="crypto-eachMarket">
            <img src={market3} alt="handshake"/>
            <div>
                <h1 class="crypto-marketTitle">Supporting</h1>
                <p class="crypto-darkPara">
                    Don’t worry if you’re new to crypto and digital 
                    currencies – Skrill makes setting up a cryptocurrency 
                    wallet easy.
                </p>
            </div>
        </div>
        <div class="crypto-eachMarket">
            <img src={market4} alt="handshake"/>
            <div>
                <h1 class="crypto-marketTitle">Aligned Incentives</h1>
                <p class="crypto-darkPara">
                Our earnings come solely from extra profits, making your success our top priority.
                </p>
            </div>
        </div>
    </div>
    <div class="crypto-sectionPic crypto-marketspicSection" id="sectionPic">
       <h1 class="crypto-marketspicHeader">CRYPTOCURRENCY</h1>
       <div className="crypto-marketsPicContainer">
            <div className="crypto-marketPic crypto-marketPic1">
                <img src={percentIcon} alt="Percentage Icon" />
                <article className="crypto-marketTitle">Hot Sale</article>
            </div>

            <div className="crypto-marketPic crypto-marketPic2">
                <img src={bitcoinIcon} alt="Bitcoin Icon" />
                <article className="crypto-marketTitle">Bitcoin</article>
            </div>

            <div className="crypto-marketPic crypto-marketPic3">
                <img src={ethereumIcon} alt="Ethereum Icon" />
                <article className="crypto-marketTitle">Ethereum</article>
            </div>

            <div className="crypto-marketPic crypto-marketPic4">
                <img src={handshakeIcon} alt="Handshake Icon" />
                <article className="crypto-marketTitle">Connecting</article>
            </div>
        </div>
        </div>
</section>


<section class="crypto-gridSection">
    <div class="crypto-sectionDesc crypto-dashboardDesc">
        <h1 class="crypto-sectionHeader">Trade crypto in seconds</h1>
        <p class="crypto-sectionPara">
            But with Swift HFT, you can trade on bitcoin  ethereum, solana and many more networks from your own phone! 
            You no longer have to worry about costly transactions.
        </p>
       <Link to='/dashboard'> <button class="crypto-btn">Explore Now</button></Link>
    </div>

    <div class="crypto-sectionPic crypto-dashboardPic">
    <img src={dashboardDark} alt="Dashboard Dark" />
    </div>

</section>

<div class="crypto-fundSection">
    <div class="crypto-sectionDesc">
        <h1 class="crypto-sectionHeader">Control your funds</h1>
        <p class="crypto-sectionPara">
            Our mining pool offers some of the most competitive contracts in the market.
        </p>
        <div className="crypto-fundsContainer">
            <div className="crypto-fund">
                <img src={currencyIcon} alt="cryptocurrency" />
                <h1 className="crypto-fundType">Support All Currency</h1>
                <p className="crypto-darkPara">
                    Accept and process payments from all types of currencies.
                </p>
            </div>

            <div className="crypto-fund">
                <img src={blockchainIcon} alt="blockchain" />
                <h1 className="crypto-fundType">Block Chain System</h1>
                <p className="crypto-darkPara">
                    How it Works, Benefits and its Deployment in Financial
                </p>
            </div>

            <div className="crypto-fund">
                <img src={sellIcon} alt="fund selling" />
                <h1 className="crypto-fundType">Fund Selling</h1>
                <p className="crypto-darkPara">
                    How to Profit in the Crypto Markets through Investing
                </p>
            </div>

            <div className="crypto-fund">
                <img src={cardIcon} alt="crypto card" />
                <h1 className="crypto-fundType">Crypto Card</h1>
                <p className="crypto-darkPara">
                    Crypto Cards and Why Cryptocurrency are the Future
                </p>
            </div>
        </div>
    </div>
</div>

<section class="crypto-gridSection">
    <div class="crypto-sectionDesc crypto-newsletterDesc">
        <h1 class="crypto-sectionHeader">Subscribe news!</h1>
        <p class="crypto-sectionPara">
            This is where crypto news websites come in handy and stay up-to-date this sphere.
        </p>
        <form action="#" class="crypto-newsletter">
            <input type="email" name="" id="newsletter" placeholder="enter your email"/>
            <button type="submit" class="crypto-btn crypto-primaryBtn">Subscribe</button>
        </form>
    </div>
    <div class="crypto-sectionPic crypto-bouncepic crypto-newsletterPic">
    <img src={newsletterImage} alt="Newsletter" />
    </div>
</section>


<CryptoFooter />

{/* <script src="./script.js"></script> */}

    </div>
  );
};

export default CryptoHome;
