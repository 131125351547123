import React from 'react';
import logoWhite from './img/logo-white.png';
import aboutHero from './img/about-hero.png';
import aboutImage1 from './img/about-image-1.jpg';
import aboutImage2 from './img/about-image-2.jpg';
import fastForwardIcon from './img/fast-forward-icon-white.svg';
import supportAgentIcon from './img/support-agent-icon-white.svg';
import progressWarningIcon from './img/progress-warning-icon-white.svg';
import aboutVideoThumbnail from './img/about-video.jpg';
import playIcon from './img/video-play-icon-white.svg';
import teamImage1 from './img/team-image-1.jpg';
import teamImage2 from './img/team-image-2.jpg';
import teamImage3 from './img/team-image-3.jpg';
import facebookIcon from './img/Facebook_black.svg';
import vimeoIcon from './img/Vimeo_black.svg';
import twitterIcon from './img/Twitter_black.svg';
import instagramIcon from './img/Instagram_black.svg';
import facebookWhiteIcon from './img/fabook-icon-white.svg';
import twitterWhiteIcon from './img/twitter-icon-white.svg';
import linkedinWhiteIcon from './img/inkedin-icon-white.svg';
import whatsappWhiteIcon from './img/whatsapp-icon-white.svg';
import './commonStyle.css';
import './style.css';
import CryptoFooter from './CryptoFooter';
import Navbar from '../../components/navbar/Navbar';
function CryptoAbout() {
  return (
    <div className='crypto'>
        <Navbar />

      <section className="crypto-gridSection">
        <div className="crypto-sectionDesc crypto-aboutusDesc">
          <h1 className="crypto-headline">
            The Company History
          </h1>
          <p className="crypto-sub-headline">
          SWIFT HFT was founded to help investors capture the high returns available in early-stage token trading. With a focus on achieving 50% returns every two weeks, our team leverages years of trading experience and cutting-edge technology. Our automated sniper bot ensures we’re positioned early for the highest potential gains. And because we earn only from additional profits, we’re dedicated to delivering consistent, profitable results for our clients.
          </p>
        </div>
        <div className="crypto-sectionPic crypto-bouncepic crypto-aboutusPic" id="sectionPic">
          <img src={aboutHero} alt="Company History" />
        </div>
      </section>

      <div className="crypto-statusContainer">
        <div className="crypto-status">
          <h1><b>Ambitious</b>  Returns</h1>
          <p>ur 50% target every two weeks lets you capitalize on high-growth opportunities in new token markets.</p>
        </div>
        <div className="crypto-status">
          <h1><b>Aligned</b> Earnings Model</h1>
          <p>By only profiting from the extra gains, we keep your financial success at the core of our mission.</p>
        </div>
        <div className="crypto-status">
          <h1><b>Early Access</b> Advantage</h1>
          <p>sing DEX Screener and our sniper bot, we access newly launched tokens quickly to secure the best entry points.</p>
        </div>
      </div>

      <div className="crypto-services">
        <div className="crypto-aboutusimgContainer">
          <img src={aboutImage1} alt="About Image 1" />
          <img src={aboutImage2} alt="About Image 2" />
        </div>
        <div className="crypto-offers">
          <div className="crypto-eachOffer">
            <img src={fastForwardIcon} alt="Fast Management Icon" />
            <div className="crypto-offerDesc">
              <h1>Fast Management</h1>
              <p>The Complete Guide to Management and How to Implement it Quickly and Effectively</p>
            </div>
          </div>
          <div className="crypto-eachOffer">
            <img src={supportAgentIcon} alt="Best Support Icon" />
            <div className="crypto-offerDesc">
              <h1>Best Support</h1>
              <p>The Best Support for Online crypto & How to Craft a Compelling Description</p>
            </div>
          </div>
          <div className="crypto-eachOffer">
            <img src={progressWarningIcon} alt="Best Progress Icon" />
            <div className="crypto-offerDesc">
              <h1>Best Progress</h1>
              <p>What is a Crypto-Mining Malware and Why You MUST Protect Against Them</p>
            </div>
          </div>
        </div>
      </div>

      <section className="crypto-videoSection">
        <h1 className="crypto-videoSectionHeader"></h1>
        <div className="crypto-videoContainer">
          <div className="crypto-video">
            <img src={aboutVideoThumbnail} className="crypto-thumbnail" alt="Video Thumbnail" />
            {/* <img src={playIcon} className="crypto-playIcon" alt="Play Icon" /> */}
          </div>
          <p>
          At Swift, protecting your investment is just as important as achieving strong returns. While we focus on maximizing gains through high-potential opportunities, we also prioritize safety by carefully selecting tokens and implementing risk-mitigation strategies. Here’s how we ensure a secure and responsible approach to crypto investing.
          </p>
        </div>
       
      </section>
      <div className='instruction'>
      <div className="crypto-eachOffer">
            
            <div className="crypto-offerDesc">
              <h1>1. Focus on Newly Launched Tokens with Locked Liquidity</h1>
              <p>We exclusively invest in newly launched tokens with locked liquidity, ensuring that funds committed to the token cannot be suddenly withdrawn. Locked liquidity is crucial for protecting against "rug pulls" and other risks associated with early-stage tokens, offering a layer of security and stability as the token gains traction.
              </p>
            </div>
          </div>
          <div className="crypto-eachOffer">
            
            <div className="crypto-offerDesc">
              <h1>2. Rigorous Screening of New Tokens</h1>
              <p>Before we invest, we conduct thorough assessments to select only the most promising tokens. By evaluating factors such as liquidity lock, project credibility, and tokenomics, we reduce risk while focusing on opportunities with substantial growth potential. This careful screening helps us maintain a high standard of security for each trade.
              </p>
            </div>
          </div>
          <div className="crypto-eachOffer">
            
            <div className="crypto-offerDesc">
              <h1>3.  Real-Time Monitoring and Data Analysis</h1>
              <p>Our platform is equipped with real-time monitoring tools that track market conditions and new token launches on DEX Screener. This enables us to stay ahead of any market shifts, acting quickly to capture opportunities or mitigate risk based on the latest market data.
              </p>
            </div>
          </div>
          <div className="crypto-eachOffer">
            
            <div className="crypto-offerDesc">
              <h1>4. Precision Execution with Sniper Bot Technology</h1>
              <p>With our advanced sniper bot, we enter and exit trades at precise moments, securing the best possible entry points on newly launched tokens. This speed and accuracy help reduce exposure to high volatility, protecting your investment while enhancing returns.
              </p>
            </div>
          </div>
          <div className="crypto-eachOffer">
            
            <div className="crypto-offerDesc">
              <h1>5. Secure & Transparent Operations</h1>
              <p>We implement industry-standard security protocols to protect all transactions and data on our platform. Additionally, our transparent reporting system allows you to monitor your investments in real-time, ensuring you’re always informed of your portfolio’s status and performance.
              </p>
            </div>
          </div>
          <div className="crypto-eachOffer">
            
            <div className="crypto-offerDesc">
              <h1>6. Aligned Incentives for Client Success</h1>
              <p>Our earnings come solely from the extra profits we generate, meaning that our success is directly tied to yours. This model keeps our focus on sustainable growth and careful risk management, as our incentives are aligned with your financial goals.
              </p>
            </div>
          </div>
      </div>

      {/* <section className="crypto-teamSection">
        <h1 className="crypto-sectionHeader">Our Strong & Creative Team</h1>
        <div className="crypto-carouselContainer">
          {[{
            imgSrc: teamImage1,
            name: "Rebecca Walters",
            position: "UI UX Designer"
          }, {
            imgSrc: teamImage2,
            name: "Richard Taylor",
            position: "Developer"
          }, {
            imgSrc: teamImage3,
            name: "Bertha Earley",
            position: "Content writer"
          }].map((member, index) => (
            <div className="crypto-eachCarousel crypto-eachTeam" key={index}>
              <div className="crypto-teamPic">
                <img src={member.imgSrc} alt={member.name} />
                <div className="crypto-teamSocialLink">
                  <img src={facebookIcon} alt="Facebook" />
                  <img src={vimeoIcon} alt="Vimeo" />
                  <img src={twitterIcon} alt="Twitter" />
                  <img src={instagramIcon} alt="Instagram" />
                </div>
              </div>
              <div className="crypto-teamDesc">
                <h1 className="crypto-teamName">{member.name}</h1>
                <p className="crypto-position">{member.position}</p>
              </div>
            </div>
          ))}
        </div>
      </section> */}
      <CryptoFooter />

    </div>
  );
}

export default CryptoAbout;
