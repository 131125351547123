import React from 'react'
import './comingsoon.css'
function ComingSoon() {
  return (
    <div className='comingsoon'>
        <div>
           <p> Coming Soon</p>
        </div>
    </div>
  )
}

export default ComingSoon